<template>
  <div>
    <custom-toolbar
      :title="$t('Payments')"
      :total="$store.getters['payment/total']"
      @refresh="getPayments"
    />
    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-1">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.payment.field.reference"
              label="Filtrer par la reférence"
              outlined
              dense
              placeholder="Filtrer par la reférence"
              hide-details="auto"
              :rules="rules.reference"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.payment.field.svc_number"
              label="Filtrer par compte"
              outlined
              dense
              placeholder="Filtrer par compte"
              hide-details="auto"
              :rules="rules.svc_number"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.payment.field.transaction_id"
              label="Filtrer par ID transaction"
              outlined
              dense
              placeholder="Filtrer par ID transaction"
              hide-details="auto"
              :rules="rules.transaction_id"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="$store.state.payment.field.op_api_id"
              :items="opApisItems"
              label="Rechercher par moyen de paiment"
              outlined
              dense
              placeholder="Rechercher par moyen de paiment"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              :rules="rules.op_api_id"
              @input="getPayments(1)"
            >
              <template v-slot:selection="{ item, index }">
                <div class="d-flex align-center">
                  <v-avatar
                    color="primary"
                    class="v-avatar-light-bg primary--text"
                    size="32"
                  >
                    <v-img
                      :src="item.image"
                    ></v-img>
                  </v-avatar>
                  <div class="d-flex flex-column ms-3">
                    <span class="d-block text--primary text-truncate">{{ item.text }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex align-center">
                  <v-avatar
                    color="primary"
                    class="v-avatar-light-bg primary--text"
                    size="32"
                  >
                    <v-img
                      :src="item.image"
                    ></v-img>
                  </v-avatar>
                  <div class="d-flex flex-column ms-3">
                    <span class="d-block text--primary text-truncate">{{ item.text }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>
      <div class="m-1">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.payment.field.base_amount"
              label="Rechercher par montant"
              outlined
              dense
              hide-details="auto"
              placeholder="Rechercher par montant"
              :rules="rules.base_amount"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.payment.field.amount"
              label="Rechercher par montant hors frais"
              outlined
              dense
              hide-details="auto"
              placeholder="Rechercher par montant hors frais"
              :rules="rules.amount"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="$store.state.payment.field.payment_type_id"
              :items="$store.getters['payment/paymentTypes']"
              item-text="label"
              item-value="id"
              label="Rechercher par type de paiement"
              outlined
              dense
              placeholder="Rechercher par type de paiement"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              :rules="rules.payment_type_id"
              @input="getPayments(1)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="$store.getters['payment/payments']"
        :items-per-page="$store.getters['payment/per_page']"
        :page.sync="$store.getters['payment/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        :loading="isDialogVisible"
        loading-text="Chargement..."
        @page-count="currentPage = $event"
      >
        <template #[`item.user`]="{item}">
          {{ item.user !== null ? (item.user.name !== null ? item.user.name : item.user.phone_number) : null }}
        </template>
        <template #[`item.road.user`]="{item}">
          {{ item.road && item.road.user !== null ? (item.road.user.name !== null ? item.road.user.name : item.road.user.phone_number) : null }}
        </template>
        <template #[`item.base_amount`]="{item}">
          <v-chip
            small
            color="primary"
          >
            {{ formatAmount(item.base_amount) }}
          </v-chip>
        </template>
        <template #[`item.amount`]="{item}">
          <v-chip
            small
            color="secondary"
          >
            {{ formatAmount(item.amount) }}
          </v-chip>
        </template>
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="item.status !== null ? item.status.color: ''"
          >
            {{ item.status !== null ? item.status.label : item.op_status }}
          </v-chip>
        </template>
        <template #[`item.payment_type.label`]="{item}">
          <v-chip
            v-if="item.payment_type.code === 'CASHIN'"
            small
            color="primary"
          >
            <v-icon
              class="me-1"
              size="18"
            >
              {{ icons.mdiCashPlus }}
            </v-icon>
            {{ item.payment_type.label }}
          </v-chip>
          <v-chip
            v-else
            small
            color="secondary"
          >
            <v-icon
              class="me-1"
              size="18"
            >
              {{ icons.mdiCashMinus }}
            </v-icon>
            {{ item.payment_type.label }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.destination`]="{item}">
          {{ item.destination ? item.destination.city.name : item.road ? item.road.destination.name : null }}
        </template>

        <template #[`item.op_api.operator`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <v-img
                :alt="item.op_api.operator.label"
                :src="item.op_api.operator.logo_path"
              ></v-img>
            </v-avatar>
          </div>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :edit="false"
            :remove="false"
            :lock="false"
            @view="openItem(item)"
            @verifystatus="verifyStatus(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.payment.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getPaymentsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getPayments"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline, mdiDotsVertical, mdiDeleteOutline, mdiPencilOutline, mdiAccountLock, mdiLockOpen, mdiEyeOutline, mdiRefresh,
  mdiCashPlus, mdiCashMinus, mdiCashCheck,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { ref, onMounted, watch } from '@vue/composition-api/dist/vue-composition-api'
import CityInput from '@core/components/autocomplete/CityInput'
import { formatDate } from '@core/date/dateFunctions'
import { useRouter } from '@core/utils'
import {intToCurrency, notify} from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";

export default {
  name: 'Payment',
  components: {ButtonsGroup, CustomToolbar, CityInput, LoaderDialog },

  setup() {
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const message = ref(null)
    const itemsPerPage = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const messageDialog = ref('')
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const dialogDelete = ref(false)
    const isAlertVisible = ref(false)
    const { router } = useRouter()
    const rules = {
      svc_number: [true],
      reference: [true],
      nb_passagers: [true],
      transaction_id: [true],
      base_amount: [true],
      amount: [true],
      payment_type_id: [true],
      op_api_id: [true],
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const opApisItems = ref([])

    const save = d => {
      menuref.value.save(d)
    }

    const formattingDate = date => formatDate(date)

    const deleteItemConfirm = () => {
      closeDelete()
    }

    const openItem = item => {
      router.push({ name: 'showpayment', params: { id: item.id } })
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const getPaymentTypes = () => {
      store.dispatch('payment/getpaymentTypes').catch(error => {
        console.log(error.response.data.message)
      })
    }

    const getOpApis = () => {
      store.dispatch('payment/getOpApis').then(() => {
        store.getters['payment/opApis'].forEach(elt => {
          opApisItems.value.push({ text: elt.operator.label, value: elt.id, image: elt.operator.logo_path })
        })
      }).catch(error => {
        console.log(error.response.data.message)
      })
    }

    const getPayments = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('payment/getPaymentsList', { page, field: store.state.payment.field }).then(() => {
        currentPage.value = store.getters['payment/current_page']
        lastPage.value = store.getters['payment/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false

        /* Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        }) */
      }).catch(errorserver => {
        message.value = errorserver.response.data.message
        if (errorserver.response.data.errors !== undefined) {
          rules.svc_number = errorserver.response.data.errors.svc_number !== undefined ? errorserver.response.data.errors.svc_number : [true]
          rules.reference = errorserver.response.data.errors.reference !== undefined ? errorserver.response.data.errors.reference : [true]
          rules.nb_passagers = errorserver.response.data.errors.nb_passagers !== undefined ? errorserver.response.data.errors.nb_passagers : [true]
          rules.transaction_id = errorserver.response.data.errors.transaction_id !== undefined ? errorserver.response.data.errors.transaction_id : [true]
          rules.payment_type_id = errorserver.response.data.errors.payment_type_id !== undefined ? errorserver.response.data.errors.payment_type_id : true
          rules.base_amount = errorserver.response.data.errors.base_amount !== undefined ? errorserver.response.data.errors.base_amount : true
          rules.amount = errorserver.response.data.errors.amount !== undefined ? errorserver.response.data.errors.amount : [true]
          rules.op_api_id = errorserver.response.data.errors.op_api_id !== undefined ? errorserver.response.data.errors.op_api_id : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    const refreshPayments = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('payment/refeshPaymentsList', { page, field: store.state.payment.field }).then(() => {
        currentPage.value = store.getters['payment/current_page']
        lastPage.value = store.getters['payment/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false

        /* Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        }) */
      }).catch(error => {
        message.value = error.response.data.message
        if (error.response.data.errors !== undefined) {
          rules.svc_number = error.response.data.errors.svc_number !== undefined ? error.response.data.errors.svc_number : [true]
          rules.reference = error.response.data.errors.reference !== undefined ? error.response.data.errors.reference : [true]
          rules.nb_passagers = error.response.data.errors.nb_passagers !== undefined ? error.response.data.errors.nb_passagers : [true]
          rules.transaction_id = error.response.data.errors.transaction_id !== undefined ? error.response.data.errors.transaction_id : [true]
          rules.payment_type_id = error.response.data.errors.payment_type_id !== undefined ? error.response.data.errors.payment_type_id : true
          rules.base_amount = error.response.data.errors.base_amount !== undefined ? error.response.data.errors.base_amount : true
          rules.amount = error.response.data.errors.amount !== undefined ? error.response.data.errors.amount : [true]
          rules.op_api_id = error.response.data.errors.op_api_id !== undefined ? error.response.data.errors.op_api_id : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    const filterItems = () => {
      setTimeout(() => {
        getPayments()
      }, 1500)
    }

    const getPaymentsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.payment.field.paginate = per_page
        itemsPerPage.value = parseInt(per_page, 10)
        store.dispatch('payment/getPaymentsList', {
          page: currentPage.value,
          field: store.state.payment.field,
        }).then(() => {
          currentPage.value = store.getters['payment/current_page']
          lastPage.value = store.getters['payment/last_page']
          isDialogVisible.value = false
          isAlertVisible.value = false

          /* Object.keys(this.rules).forEach(key => {
            this.rules[key] = [true]
          }) */
        })
      }
    }

    const getStartOmsId = event => {
      store.state.payment.field.base_amount = event ? event.toString() : null
      getPayments()
    }

    const getEndOmsId = event => {
      store.state.payment.field.amount = event ? event.toString() : null
      getPayments()
    }

    const verifyStatus = item => {
      isDialogVisible.value = true
      store.dispatch('payment/verifyPaymentStatus', item).then(response => {
        message.value = response.message
        isDialogVisible.value = false
        isAlertVisible.value = true
        notify(true, true, response.message)
        error.value = false
      }).catch(error => {
        console.log(error.response.data.message)
        message.value = error.response.data.message
        isDialogVisible.value = false
        isAlertVisible.value = true
        notify(true, true, error.response.data.message)
        error.value = true
      })
    }

    watch(() => store.state.user.lang, lang => {
      refreshPayments(store.getters['payment/current_page'])
    })

    onMounted(() => {
      getPayments(store.getters['payment/current_page'])
      getOpApis()
      getPaymentTypes()
    })

    return {
      formatAmount,
      getPaymentsPerPage,
      deleteItemConfirm,
      openItem,
      closeDelete,
      formattingDate,
      getStartOmsId,
      getEndOmsId,
      verifyStatus,
      getPayments,
      save,
      opApisItems,
      date,
      menu,
      picker,
      menuref,
      message,
      currentPage,
      lastPage,
      error,
      messageDialog,
      isDialogVisible,
      dialog,
      dialogDelete,
      isAlertVisible,
      rules,
      filterItems,
      headers: [
        { text: 'REFERENCE', value: 'reference' },
        { text: 'ID TRANSACTION', value: 'transaction_id' },
        { text: 'MOTIF', value: 'pay_reason.label' },
        { text: 'DEPART', value: 'road.city.name' },
        { text: 'ARRIVEE', value: 'destination' },
        { text: 'INITIATEUR', value: 'user' },
        { text: 'COMPTE', value: 'svc_number' },
        { text: 'MONTANT', value: 'base_amount' },
        { text: 'MONTANT HORS FRAIS', value: 'amount' },
        { text: 'CHAUFFEUR', value: 'road.user' },
        { text: 'MOYEN DE PAIMENT', value: 'op_api.operator' },
        { text: 'TYPE DE PAIEMENT', value: 'payment_type.label' },
        { text: 'STATUT', value: 'status' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      customHeader: [
        { text: 'REFERENCE', value: 'reference', class: 'sticky-header' },
      ],
      itemsPerPage: parseInt(store.state.payment.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiCashMinus,
        mdiCashPlus,
        mdiCashCheck,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
}
.v-data-table /deep/ .v-data-table__wrapper {
  //overflow: unset;
}
/*table > tbody > tr > td:nth-child(2),
table > thead > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background: white;
}
table > thead > tr > th:nth-child(2) {
  z-index: 9998;
}*/
</style>
